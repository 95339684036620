import { CF_URL } from '@ask-cf/config'

export const ROUTES = {
  ABOUT: (locale: string) => buildLocaleRoute(locale, 'about'),
  QUESTIONS: (locale: string) => buildLocaleRoute(locale, 'questions'),
  YOUR_QUESTIONS: (locale: string) => buildLocaleRoute(locale, 'your-questions'),
  YOUR_FOLLOWED_QUESTIONS: (locale: string) => buildLocaleRoute(locale, 'your-followed-questions'),
  YOUR_ANSWERS: (locale: string) => buildLocaleRoute(locale, 'your-answers'),
  ALL_TOPICS: (question: string, locale: string) =>
    `${buildLocaleRoute(locale, `topics`)}${question ? `?q=${question}` : ''}`,
  TOPIC_DETAILS: (slug: string, locale: string) => buildLocaleRoute(locale, `topics/${slug}`),
  QUESTION_DETAILS: (topicSlug: string, questionSlug: string, locale: string) =>
    buildLocaleRoute(locale, `topics/${topicSlug}/${questionSlug}`),
  ASK_A_QUESTION: (locale: string) => buildLocaleRoute(locale, 'ask-a-question'),
  RANDOM_QUESTION: (locale: string) => buildLocaleRoute(locale, 'random-question'),
  BADGES: (locale: string) => buildLocaleRoute(locale, 'badges'),
  LEADERBOARD: (locale: string) => buildLocaleRoute(locale, 'leaderboard'),
  HOME: (locale: string) => buildLocaleRoute(locale, ''),
  SEARCH_RESULTS: (question: string, page: string, locale: string) =>
    `${buildLocaleRoute(locale, 'search')}${buildSearchParams({ q: question, page })}`,
  NOTIFICATIONS: (locale: string) => buildLocaleRoute(locale, 'notifications'),
  COINS: (locale: string) => (locale === 'en' ? `${CF_URL}/coins/` : `${CF_URL}/${locale}/coins/`),
  LOG_OUT: (currentUrl: string) => `${CF_URL}/my-account/customer-logout?rd=${currentUrl}`,
  LOGIN: (currentUrl: string) => `${CF_URL}/login?rd=${currentUrl}`,
  SIGN_UP: (currentUrl: string) => `${CF_URL}/signup?rd=${currentUrl}`,
  MY_ACCOUNT: `${CF_URL}/my-account/my-profile/`,
  SEARCH: (locale: string) => buildLocaleRoute(locale, 'search'),
  REDIRECT_BASE: (locale: string) => buildLocaleRoute(locale, 'redirect'),
  UNSUBSCRIBE: (locale: string) => buildLocaleRoute(locale, 'unsubscribe'),
}

const buildLocaleRoute = (locale: string, route: string): string =>
  locale && locale !== 'en' ? `/${locale}/${route}/` : `/${route === '' ? '' : `${route}/`}`

const buildSearchParams = (params: Record<string, string>) => {
  if (Object.keys(params).length === 0) {
    return ''
  }
  const searchParams = new URLSearchParams()
  Object.entries(params).forEach(([key, value]) => {
    searchParams.append(key, value)
  })
  return `?${searchParams.toString()}`
}
