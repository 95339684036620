'use client'

import Image from 'next/image'
import * as React from 'react'
import { useLocale, useTranslations } from 'next-intl'

import { Link } from '@ask-cf/components/link'
import NotFoundDesktop from '@ask-cf/assets/images/not-found-desktop.webp'
import NotFoundMobile from '@ask-cf/assets/images/not-found-mobile.png'
import { ROUTES } from '@ask-cf/constants/app-routes'

// eslint-disable-next-line no-restricted-exports
export default function Error() {
  const locale = useLocale()

  const t = useTranslations('ask_cf.pages.error')
  return (
    <section className="mt-6 flex w-full items-start justify-center">
      <Panel>
        <header className="text-pink-500">
          <h1 className="spacing text-[96px] font-medium leading-[144px] -tracking-[5%]">500</h1>
          <h2 className="-mt-5 text-center text-xl font-semibold">{t('title')}</h2>
        </header>
        <p className="text-text-tertiary mt-4 max-w-[536px] text-center leading-6">
          {t('content')}
        </p>
        <Image className={'mt-5 hidden xl:block'} src={NotFoundDesktop} alt="500"></Image>
        <Image className={'mt-5 block xl:hidden'} src={NotFoundMobile} alt="500"></Image>
        <Link
          href={ROUTES.HOME(locale)}
          className="rounded-2 hover:bg-text-hover mt-4 w-[294px] bg-blue-700 py-[16px] text-center font-semibold text-white xl:mt-8 xl:w-[352px]"
        >
          {t('back_button')}
        </Link>
      </Panel>
    </section>
  )
}

function Panel({ children }: React.PropsWithChildren) {
  return (
    <div className="rounded-2 flex w-full max-w-[1200px] flex-col items-center bg-white px-4 py-5 xl:pb-10">
      {children}
    </div>
  )
}
